import React, { useContext, useState } from "react";
import { MenuContext } from "react-flexible-sliding-menu";
import { RxCross1 } from "react-icons/rx";
// pop up
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Franchise from "./popUps/franchise";
// for reservation form
import Reservation from "./popUps/reservation";

const SlideMenu = () => {
  // to close full page menu
  const { closeMenu } = useContext(MenuContext);

  // for reservation pop up
  const ReserationPopUp = withReactContent(Swal);

  const showReservationPopup = () => {
    ReserationPopUp.fire({
      title: "Reserve a Table",
      html: <Reservation />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const [dropdown, setDropdown] = useState(false);

  // franchise pop up
  const FranchisePopUp = withReactContent(Swal);
  const showFranchisePopup = () => {
    FranchisePopUp.fire({
      // title: "Franchising Initial Inquiry Form",
      title: "Franchising Inquiry",
      html: <Franchise />,
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  return (
    <nav className="menu table h-screen w-screen">
      {/* <img
        className="absolute w-40 inset-10"
        src={require("../assets/images/trisara main.png")}
        alt="trisara logo"
      /> */}
      <div className="nav-links table-cell p-10 align-middle text-xl leading-[6vh]">
        <a className="block" href="/">
          Home
        </a>
        <div className="dropdown">
          <button>Outlet</button>
          <ul className="dropdown-options pl-8">
            <li>
              <a href="/trisara">Trisara Restaurant</a>
            </li>
            <li>
              <a href="/peros/p_thamel">Peros</a>
            </li>
            <li>
              <a href="/bakery">Bakery</a>
            </li>
            <li>
              <a
                href="https://www.jadecity.com.np"
                target="_blank"
                rel="noreferrer"
              >
                Jade City Hotel
              </a>
            </li>
            <li>
              <a
                href="https://www.evolvefitness.com.np"
                target="_blank"
                rel="noreferrer"
              >
                Evolve Fitness
              </a>
            </li>
          </ul>
        </div>
        <a className="block" href="/openingSoon">
          Achievements
        </a>
        <a
          onClick={() => {
            showFranchisePopup();
          }}
          onMouseEnter={() => setDropdown(false)}
        >
          Franchise
        </a>
        <a className="block" href="/careers">
          Careers
        </a>
        <button
          className="mt-5 w-full rounded-md bg-black py-1 font-semibold text-white transition-all duration-500 ease-in-out hover:bg-[#ebf3e8] hover:text-black hover:outline hover:outline-2 hover:outline-black"
          onClick={() => {
            showReservationPopup();
          }}
        >
          Reserve Now
        </button>
      </div>

      {/* close full page menu */}
      <span className="toggleMenu" onClick={closeMenu}>
        <RxCross1 />
      </span>
    </nav>
  );
};

export default SlideMenu;
