import React from "react";
import { Link } from "react-router-dom";

const Menu = ({ outlet }) => {
  const menuCategories = [
    {
      franchise: "trisara",
      category: "Appetizer",
      img: "appetizer.png",
      link: `menu#APPETISER`,
    },
    {
      franchise: "trisara",
      category: "Main Course",
      img: "2023_10_06_TrisaraMainCourse_1080x1080px.jpg",
      link: "menu#MAIN_COURSE",
    },
    {
      franchise: "trisara",
      category: "Drinks",
      img: "drinks.png",
      link: "menu#DRINKS",
    },
    {
      franchise: "trisara",
      category: "Desserts",
      img: "desserts.png",
      link: "menu#DESSERT",
    },
  ];
  return (
    <>
      {outlet.franchise === "https://api.trisaranepal.com/franchises/jade/" ||
      outlet.franchise === "https://api.trisaranepal.com/franchises/evolve/" ? (
        ""
      ) : (
        <div className="menu text-center">
          <div className="detail w-screen px-2 pt-5 lg:w-[63vw]">
            <p className="pre-heading">Discover</p>
            <h1 className="font-bold text-3xl py-3">Menu</h1>
            <p>{outlet.menu_description}</p>
          </div>
          <div className="menu-items grid grid-cols-2 gap-4 px-2 lg:grid-cols-4 lg:px-28">
            {menuCategories.map((item) => (
              <Link key={item.category} to={item.link}>
                <p className="outlet-menu w-full pt-[42%] sm:text-[30px] text-[17px]">
                  {item.category}
                </p>
                <img
                  src={require("../../assets/images/" + item.img)}
                  alt={item.franchise + " " + item.category}
                  className="menu-item w-full h-full"
                />
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Menu;
