import React from "react";

import useAxios from "../../utils/axios/useAxios";

const LiveMusic = ({ outlet }) => {
  const axios = useAxios();

  const [liveMusic, setLiveMusic] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`/gallery/`, {
        params: {
          outlet: outlet.id,
          live_music: true,
        },
      })
      .then((response) => {
        setLiveMusic(response.data.slice(0, 4));
      });
  }, [axios, outlet.id]);
  return (
    <div className="live-music relative min-h-[52vh]">
      <video
        className={`video ${
          outlet.franchise === "https://api.trisaranepal.com/franchises/jade/"
            ? ""
            : "opacity-20"
        }`}
        src={require(
          outlet.franchise === "https://api.trisaranepal.com/franchises/jade/"
            ? "../../assets/videos/IMG_5002.mp4"
            : outlet.franchise ===
              "https://api.trisaranepal.com/franchises/evolve/"
            ? "../../assets/videos/evolve.mp4"
            : "../../assets/videos/video.mp4"
        )}
        muted
        autoPlay={true}
        loop
      ></video>

      <div className="px-8 py-16 md:p-[100px] lg:grid lg:grid-cols-6 lg:gap-4">
        {outlet.franchise === "https://api.trisaranepal.com/franchises/jade/" ||
        outlet.franchise ===
          "https://api.trisaranepal.com/franchises/bakery/" ? (
          ""
        ) : outlet.franchise ===
          "https://api.trisaranepal.com/franchises/evolve/" ? (
          <div className="detail lg:col-span-4 lg:p-10 xl:px-28 xl:py-20">
            <p className="pre-heading">About Trainer</p>
            <h1 className="font-bold text-3xl py-3">
              Expert Personal Trainer & Sports Nutrition Coach
            </h1>
            <p className="live-music-text whitespace-pre-line">
              {outlet.event_description}
            </p>
          </div>
        ) : (
          <div className="detail lg:col-span-2 lg:p-10 xl:px-28 xl:py-20">
            <p className="pre-heading">Events</p>
            <h1 className="font-bold text-3xl py-3">What's On</h1>
            <p className="live-music-text whitespace-pre-line">
              {outlet.event_description}
            </p>
          </div>
        )}
        {outlet.franchise ===
        "https://api.trisaranepal.com/franchises/evolve/" ? (
          <div className="lg:col-span-2">
            <img
              className="w-full rounded-2xl"
              src={require("../../assets/images/trainer1.JPG")}
              alt="trainer2"
            />
          </div>
        ) : (
          liveMusic && (
            <div className="columns-2 md:columns-4 lg:col-span-4">
              {liveMusic.map((item) => (
                <img
                  key={item.id}
                  className="w-full rounded-3xl py-2 sm:h-80 lg:h-128 2xl:h-[50vh]"
                  src={item.thumbnail_url}
                  alt="event singers"
                />
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default LiveMusic;
