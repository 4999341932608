import React from "react";

import useAxios from "../../utils/axios/useAxios";

const Gallery = ({ outlet }) => {
  const axios = useAxios();

  const [gallery, setGallery] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`/gallery/`, {
        params: {
          outlet: outlet.id,
        },
      })
      .then((response) => {
        setGallery(response.data.slice(0, 8));
      });
  }, [axios, outlet.id]);

  return (
    <div className="gallery text-center">
      <div className="detail w-screen px-2 lg:w-[63vw]">
        <p className="pre-heading">Discover</p>
        <h1 className="font-bold text-3xl py-3">Gallery</h1>
        <p className="gallery-text px-10">{outlet.gallery_description}</p>
      </div>
      {gallery && gallery[7] ? (
        <div className=" px-2 sm:px-10 md:px-20 lg:grid grid-cols-5 lg:px-40 md:columns-0 columns-2 gap-2 lg:space-y-0 space-y-3">
          <img
            key={gallery[0].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !gallery[0].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={gallery[0].thumbnail_url}
            alt="Trisara Gallery"
          />
          <img
            key={gallery[1].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !gallery[1].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={gallery[1].thumbnail_url}
            alt="Trisara Gallery"
          />
          <img
            key={gallery[2].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !gallery[2].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={gallery[2].thumbnail_url}
            alt="Trisara Gallery"
          />
          <img
            key={gallery[3].id}
            className={`w-full rounded-3xl sm:h-[300px] lg:col-span-2 ${
              !gallery[3].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={gallery[3].thumbnail_url}
            alt="Trisara Gallery"
          />
          <img
            key={gallery[4].id}
            className={`w-full rounded-3xl sm:h-[300px] lg:col-span-2 ${
              !gallery[4].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={gallery[4].thumbnail_url}
            alt="Trisara Gallery"
          />
          <img
            key={gallery[5].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !gallery[5].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={gallery[5].thumbnail_url}
            alt="Trisara Gallery"
          />
          <img
            key={gallery[6].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !gallery[6].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={gallery[6].thumbnail_url}
            alt="Trisara Gallery"
          />
          <img
            key={gallery[7].id}
            className={`w-full rounded-3xl sm:h-[300px] ${
              !gallery[7].display_on_mobile ? "hidden sm:block" : ""
            }`}
            src={gallery[7].thumbnail_url}
            alt="Trisara Gallery"
          />
        </div>
      ) : (
        <p>Images Unavailable.</p>
      )}
    </div>
  );
};

export default Gallery;
