import React from "react";
import { BiPhone } from "react-icons/bi";
import { FiInstagram } from "react-icons/fi";
import { RiFacebookFill } from "react-icons/ri";
import { SlLocationPin } from "react-icons/sl";

const Footer = ({ outlet }) => {
  let hours = outlet.operational_hours;

  // for rights reserved
  const currentYear = new Date().getFullYear();
  return (
    <footer className="border-t-1 gap-x-14 gap-y-24 border-neutral-200 p-10 pb-7 pt-16 imac:leading-[5rem] leading-8 sm:grid sm:grid-cols-4 lg:p-24 lg:pb-7 ">
      {/* trisara */}
      <section className="pb-8 sm:col-span-2 lg:col-span-1">
        {outlet.logo ? (
          <>
            <img
              className="w-9/12"
              src={
                outlet.franchise ===
                "https://api.trisaranepal.com/franchises/bakery/"
                  ? require("../../assets/images/baking factory footer-04.png")
                  : outlet.logo
              }
              alt="logo"
            />
          </>
        ) : (
          <img
            className="w-9/12"
            src={require("../../assets/images/trisara main.png")}
            alt="logo"
          />
        )}

        <h1 className="my-5 imac:text-[70px] text-[16px] font-medium uppercase tracking-[9px] md:text-[20px] md:tracking-[14px]">
          {outlet.name}
        </h1>
        <p className="">
          {outlet.id === "j_pokhara"
            ? "At Jade City Hotel, we believe that travel is an opportunity to create memories that will last a lifetime. Through our legendary service, thoughtful amenities and commitment to craft, we inspire life's most meaningful moments, each and every day that changes everything."
            : outlet.id === "l_evolve"
            ? "Welcome to Evolve Fitness, where passion meets purpose! Established by Trisara in 2024, we are dedicated to helping you fuel your passion, sculpt your body, and elevate your life. Join us on this journey of evolution and discover the best version of yourself."
            : "TRISARA, The Third Garden of Heaven, is a Picturesque Outdoor Restaurant offering the most scrumptious Food & Drinks along with Good Music and Great times."}
        </p>
        <div className="">
          <a
            className="inline-block pr-2 pt-2 text-4xl imac:text-[80px]"
            href={outlet.instagram_url}
            target="_blank"
            rel="noreferrer"
          >
            <FiInstagram />
          </a>
          <a
            className="inline-block pr-2 pt-2 text-4xl imac:text-[80px]"
            href={outlet.facebook_url}
            target="_blank"
            rel="noreferrer"
          >
            <RiFacebookFill />
          </a>
        </div>
      </section>
      {/*groups and events  */}
      <section className="pb-8 md:pl-6 pl-0 sm:col-span-2 lg:col-span-1">
        <h2 className="pb-2 font-semibold text-xl">Groups and Events</h2>
        <a className="block" href="/events">
          Birthday Celebrations
        </a>
        <a className="block" href="/events">
          Private Events
        </a>
        <a className="block" href="/events">
          Corporate Events
        </a>
        <a className="block" href="/events">
          Family Events
        </a>
        <a className="block" href="/events">
          Other Events
        </a>
      </section>
      {/* Operation Hour */}

      {outlet.id === "j_pokhara" ? (
        <section className="pb-8 sm:col-span-2 lg:col-span-1">
          <h2 className="pb-2 font-semibold text-xl">Our Services</h2>
          <ul className="list-disc pl-5">
            <li>24 hour front Desk & Room Services</li>
            <li>Stunning Lake and Mountain View rooms</li>
            <li>Balcony in every rooms</li>
            <li>Family Rooms available</li>
            <li>Free Wi-Fi</li>
            <li>Air conditioning</li>
            <li>Non Smoking Rooms</li>
          </ul>
        </section>
      ) : outlet.operational_hours ? (
        <section className="pb-8 sm:col-span-2 lg:col-span-1">
          <h2 className="pb-2 font-semibold text-xl">Operation Hour</h2>

          <p>
            <b>Sunday: </b> {hours.Sunday[0]} - {hours.Sunday[1]}
          </p>
          <p>
            <b>Monday: </b> {hours.Monday[0]} - {hours.Monday[1]}
          </p>
          <p>
            <b>Tuesday: </b> {hours.Tuesday[0]} - {hours.Tuesday[1]}
          </p>
          <p>
            <b>Wednesday: </b> {hours.Wednesday[0]} - {hours.Wednesday[1]}
          </p>
          <p>
            <b>Thursday: </b> {hours.Thursday[0]} - {hours.Thursday[1]}
          </p>
          <p>
            <b>Friday: </b> {hours.Friday[0]} - {hours.Friday[1]}
          </p>
          <p>
            <b>Saturday: </b> {hours.Saturday[0]} - {hours.Saturday[1]}
          </p>
        </section>
      ) : (
        ""
      )}
      {/* Contact Details + Careers */}
      <section className="pb-8 sm:col-span-2 lg:col-span-1">
        {outlet.location_url ? (
          <div className="pb-5">
            <h2 className="pb-2 font-semibold text-xl">Contact Details</h2>
            <a href={outlet.location_url} target="_blank" rel="noreferrer">
              <SlLocationPin className="mr-3 inline text-xl imac:text-5xl" />
              {outlet.location}
            </a>
            <br />
            <a href={"tel:" + outlet.phone_number}>
              <BiPhone className="mr-3 inline text-xl imac:text-5xl" />
              {outlet.phone_number}
            </a>
          </div>
        ) : (
          ""
        )}
        <>
          <h2 className="pb-2 font-semibold text-xl">Careers</h2>
          <a className="block" href="/careers">
            Vacancies (Work with us)
          </a>
          <a className="block" href="/careers">
            Internship
          </a>
          <a className="block" href="/careers">
            Training
          </a>
          <a className="block" href="/careers">
            Workshops
          </a>
        </>
      </section>
      <p className="col-span-4 text-center text-sm imac:text-4xl">
        Copyright © {currentYear} Trisara Restaurant | All rights reserved
      </p>
    </footer>
  );
};

export default Footer;
